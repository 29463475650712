import styled from "styled-components";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import WishApi from "../../../api/wishApi";

function ProductItem({ data, noVideo }) {
  const videoRef = useRef(null);
  const iframeRef = useRef(null);

  const navigation = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [isWish, setIsWish] = useState(data.isWish);

  const handleMouseEnter = async () => {
    try {
      if (videoRef.current && videoRef.current.paused) {
        await videoRef.current.play();
      }
    } catch (error) {
      console.error("Error playing video:", error);
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // 비디오를 처음 상태로 초기화
    }
  };

  const wishTriggerFunc = async () => {
    try {
      await WishApi.wishTrigger(data.id);
      setIsWish(!isWish);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const formTypeUI = (type, key) => {
    switch (type) {
      case "plan":
        return (
          <div
            style={{
              padding: "4px 10px",
              background: "#FFECF3",
              borderRadius: 4,
              alignSelf: "baseline",
            }}
          >
            <PlanText $isMobile={isMobile}>{data.promotion?.title}</PlanText>
          </div>
        );

      case "best":
        return (
          <div
            style={{
              padding: "4px 8px",
              background: "#BA77EF",
              borderRadius: 4,
              alignSelf: "baseline",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 10 : 12,
                lineHeight: isMobile ? "13px" : "16.8px",
                fontWeight: 600,
                color: "#FFFFFF",
              }}
            >
              BEST
            </PretendardText>
          </div>
        );

      case "new":
        return (
          <div
            style={{
              padding: "4px 10px",
              background: "#FF965A",
              borderRadius: 4,
              alignSelf: "baseline",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 10 : 12,
                lineHeight: isMobile ? "13px" : "16.8px",
                fontWeight: 600,
                color: "#FFFFFF",
              }}
            >
              NEW
            </PretendardText>
          </div>
        );

      case "event":
        return (
          <div
            style={{
              padding: "4px 10px",
              background: "#41C1C9",
              borderRadius: 4,
              alignSelf: "baseline",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 10 : 12,
                lineHeight: isMobile ? "13px" : "16.8px",
                fontWeight: 600,
                color: "#FFFFFF",
              }}
            >
              EVENT
            </PretendardText>
          </div>
        );

      case "md":
        return (
          <div
            style={{
              padding: "4px 10px",
              background: "#FF649C",
              borderRadius: 4,
              alignSelf: "baseline",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 10 : 12,
                lineHeight: isMobile ? "13px" : "16.8px",
                fontWeight: 600,
                color: "#FFFFFF",
              }}
            >
              MD sự giới thiệu
            </PretendardText>
          </div>
        );
    }
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return Math.floor(num / 1000000) + "M";
    } else if (num >= 1000) {
      return Math.floor(num / 1000) + "K";
    } else {
      return num.toString();
    }
  }

  const getVideoEmbedUrl = () => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = data.video.link.match(regex);

    const videoId = match ? match[1] : "";
    return `https://www.youtube.com/embed/${videoId}`;

    // 추후 틱톡
    // return `https://www.tiktok.com/embed/${url.split('/')[5]}`;
  };

  const handleIframeMouseEnter = () => {
    if (iframeRef.current) {
      iframeRef.current.src = `${getVideoEmbedUrl()}?autoplay=1&mute=1`;
    }
  };

  const handleIframeMouseLeave = () => {
    if (iframeRef.current) {
      iframeRef.current.src = `${getVideoEmbedUrl()}?autoplay=0`;
    }
  };

  return (
    <>
      <div
        onClick={() => {
          navigation(`${route.product_detail}/${data.id}`);
        }}
        style={{
          width: isMobile ? "46.8%" : isTablet ? "48.5%" : 280,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {data.video?.id && !noVideo ? (
          <div
            style={{
              position: "absolute",
              left: 10,
              top: 10,
              padding: isMobile ? "5px 14px" : "5px 10px",
              background: "rgba(255, 255, 255, 0.5)",
              borderRadius: 100,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: isMobile ? 12 : 20,
                height: isMobile ? 12 : 20,
                marginRight: 4,
              }}
              src="/assets/icons/ic_eye_20.png"
              alt="ic_eye_20"
            />
            <PretendardText
              style={{
                fontSize: isMobile ? 11 : 14,
                fontWeight: 500,
                color: "#ABABAB",
              }}
            >
              {formatNumber(Number(data.viewCount))}
            </PretendardText>
          </div>
        ) : null}

        {isWish ? (
          <img
            onClick={(e) => {
              e.stopPropagation();
              wishTriggerFunc();
            }}
            style={{
              position: "absolute",
              right: 14,
              top: 14,
              width: isMobile ? 24 : 30,
              height: isMobile ? 24 : 30,
              cursor: "pointer",
              zIndex: 2,
            }}
            src="/assets/icons/heart_a.png"
            alt="headrt"
          />
        ) : (
          <img
            onClick={(e) => {
              e.stopPropagation();
              wishTriggerFunc();
            }}
            style={{
              position: "absolute",
              right: 14,
              top: 14,
              width: isMobile ? 24 : 30,
              height: isMobile ? 24 : 30,
              cursor: "pointer",
              zIndex: 2,
            }}
            src="/assets/icons/heart_d.png"
            alt="headrt"
          />
        )}

        {data.video?.id && !noVideo ? (
          data.video.link ? (
            <div
              onMouseEnter={handleIframeMouseEnter}
              onMouseLeave={handleIframeMouseLeave}
              style={{
                width: "100%",
                height: isMobile ? 218 : isTablet ? 360 : 380,
                borderRadius: 8,
              }}
            >
              <iframe
                style={{
                  borderRadius: 8,
                }}
                ref={iframeRef}
                width="100%"
                height={isMobile ? 218 : isTablet ? 360 : 380}
                src={`${getVideoEmbedUrl()}?autoplay=0`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
              />
            </div>
          ) : (
            <video
              ref={videoRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              style={{
                width: "100%",
                height: isMobile ? 218 : isTablet ? 360 : 380,
                objectFit: "cover",
                borderRadius: 8,
              }}
            >
              <source
                src={`${process.env.REACT_APP_MEDIA_URL}${data.video.videoServerFileName}`}
                type="video/mp4"
              />
            </video>
          )
        ) : (
          <img
            style={{
              width: "100%",
              height: isMobile ? 218 : isTablet ? 360 : 380,
              borderRadius: 8,
            }}
            src={`${process.env.REACT_APP_MEDIA_URL}${data.imageServerFileName}`}
            alt="product"
          />
        )}

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 6,
          }}
        >
          {data.bestFlag ? formTypeUI("best") : null}
          {data.eventFlag ? formTypeUI("event") : null}
          {data.mdFlag ? formTypeUI("md") : null}
          {data.newFlag ? formTypeUI("new") : null}
          {data.promotion && data.promotion.title ? formTypeUI("plan") : null}
        </div>
        <TitleText $isMobile={isMobile}>
          {data.video?.id && !noVideo ? data.video?.title : data.title}
        </TitleText>

        {data.video?.id && !noVideo ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              background: "#F3F1F2",
              borderRadius: 8,
              padding: isMobile ? "8px 10px" : "10px 12px",
              flex: 1,
              maxHeight: 62.78,
            }}
          >
            <img
              style={{
                width: isMobile ? 36 : 42,
                height: isMobile ? 36 : 42,
                marginRight: 8,
                borderRadius: 12,
              }}
              src={`${process.env.REACT_APP_MEDIA_URL}${data.imageServerFileName}`}
              alt="product01"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SponsorTitleText $isMobile={isMobile}>
                {data.title}
              </SponsorTitleText>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 12 : 16,
                    lineHeight: isMobile ? "16.8px" : "23.2px",
                    fontWeight: 700,
                    color: "#313131",
                    marginRight: isMobile ? 0 : 8,
                    marginTop: 3,
                    marginBottom: isMobile ? 4 : 0,
                  }}
                >
                  {Number(data.salePrice).toLocaleString()}₫
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 11 : 14,
                    lineHeight: isMobile ? "14.3px" : "19.6px",
                    fontWeight: 400,
                    color: "#939393",
                    textDecoration: "line-through",
                  }}
                >
                  {Number(data.originalPrice).toLocaleString()}đ
                </PretendardText>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 16 : 20,
                lineHeight: isMobile ? "23.2px" : "28px",
                fontWeight: 700,
                color: "#313131",
                marginRight: 8,
              }}
            >
              {Number(data.salePrice).toLocaleString()}₫
            </PretendardText>

            <PretendardText
              style={{
                fontSize: isMobile ? 14 : 16,
                lineHeight: isMobile ? "20.3px" : "23.2px",
                fontWeight: 400,
                color: "#939393",
                textDecoration: "line-through",
              }}
            >
              {Number(data.originalPrice).toLocaleString()}đ
            </PretendardText>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductItem;

const TitleText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "12px" : "18px")};
  line-height: ${(props) => (props.$isMobile ? "16.8px" : "26.1px")};
  font-weight: 500;
  color: rgb(49, 49, 49);
  margin: ${(props) => (props.$isMobile ? "8px 0px" : "12px 0px")};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const SponsorTitleText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "12px" : "14px")};
  line-height: ${(props) => (props.$isMobile ? "16.8px" : "19.6px")};
  font-weight: 500;
  color: rgb(98, 98, 98);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const PlanText = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "10px" : "12px")};
  line-height: ${(props) => (props.$isMobile ? "13px" : "16.8px")};
  font-weight: 600;
  color: rgb(255, 81, 144);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
