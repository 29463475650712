import React, { useState, useEffect } from "react";

import styled from "styled-components";

function Input(props) {
  return (
    <>
      <InputWrapper
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        style={{
          ...props.style,
        }}
        onKeyDown={props.onKeyDown}
        maxLength={props.maxLength}
        disabled={props.disabled}
        onClick={props.onClick}
        autocomplete={props.autocomplete}
      />
    </>
  );
}

export default Input;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  box-sizing: border-box;

  height: 55px;
  padding: 16px 15px;

  background: #ffffff;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  color: #313131;
  border: 2px solid #e8e8e8;
  border-radius: 8px;

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: #c4c4c4;
  }

  &:focus {
    outline: none !important;
    border-color: #ff649c !important;
  }

  &:disabled {
    outline: none;
    background: #f5f5f5;
    color: #ababab;
  }
`;
