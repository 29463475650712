import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

import PretendardText from "../atoms/text/pretendardText";
import { route } from "../../router/route";

import PCContainer from "../atoms/layout/pcContainer";
import InputSearch from "../atoms/input/inputSearch";
import { menus } from "../../constant/menu";
import {
  headerMenuState,
  headerTriggerState,
  isLoginTrigger,
} from "../../store/headerState";

import { errorHandler } from "../../util/errorHandler";
import CartApi from "../../api/cartApi";

const Header = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const containerRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(["autoLogin"]);

  const headerTriggerStateAtom = useRecoilValue(headerTriggerState);
  const IsLoginTriggerAtom = useRecoilValue(isLoginTrigger);
  const headerMenuStateAtom = useRecoilValue(headerMenuState);

  const [menuList, setMenuList] = useState(menus);

  const [keyword, setKeyword] = useState("");
  const [isMenuOn, setIsMenuOn] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    const handleClick = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsMenuOn(false);
      }
    };
    window.addEventListener("mousedown", handleClick);
    return () => window.removeEventListener("mousedown", handleClick);
  }, [containerRef]);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");
    let role = sessionStorage.getItem("role");
    let name = sessionStorage.getItem("name");
    let profile = sessionStorage.getItem("profile");

    setIsLogin(isLogin);
    setRole(role);
    setName(name);
    setProfile(profile);

    if (isLogin) {
      cartListFunc();
    }
  }, [pathname, headerTriggerStateAtom, IsLoginTriggerAtom]);

  const logoutFunc = async () => {
    removeCookie("autoLogin", { path: "/" });
    sessionStorage.clear();

    window.location.href = route.login + "?refresh=true";
  };

  const cartListFunc = async () => {
    try {
      const params = {
        page: 1,
        size: 0,
      };

      const response = (await CartApi.getList(params)).data.data;
      setCartTotal(response.totalCount);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <HeaderSection>
      <HeaderTopWrapper>
        {isLogin ? (
          <PCContainer
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                navigation(route.mypage_account_manage);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 20,
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 28,
                  height: 28,
                  borderRadius: 99,
                  marginRight: 4,
                }}
                src={
                  profile
                    ? `${process.env.REACT_APP_MEDIA_URL}${profile}`
                    : "/assets/icons/empty_person.png"
                }
                alt="empty_person"
              />
              <PretendardText
                style={{
                  fontSize: 16,
                  color: "#151515",
                  fontWeight: 600,
                }}
              >
                MY
              </PretendardText>
            </div>

            <div
              onClick={() => {
                logoutFunc();
              }}
              style={{
                background: "#fff",
                padding: "4px 10px",
                borderRadius: 6,
                cursor: "pointer",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#434343",
                  fontWeight: 500,
                }}
              >
                Đăng xuất
              </PretendardText>
            </div>

            <PretendardText
              onClick={() => {
                navigation(`${route.customer_center}/1`);
              }}
              style={{
                cursor: "pointer",
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#151515",
                fontWeight: 600,
                marginLeft: 20,
              }}
            >
              Hỗ trợ khách hàng
            </PretendardText>
          </PCContainer>
        ) : (
          <PCContainer
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                navigation(`${route.login}`);

                setIsMenuOn(false);
              }}
              style={{
                background: "#fff",
                padding: "4px 10px",
                marginRight: 20,
                borderRadius: 6,
                cursor: "pointer",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#434343",
                  fontWeight: 500,
                }}
              >
                Đăng nhập
              </PretendardText>
            </div>

            <PretendardText
              onClick={() => {
                navigation(`${route.register_type}`);

                setIsMenuOn(false);
              }}
              style={{
                cursor: "pointer",
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#151515",
                fontWeight: 600,
                marginRight: 20,
              }}
            >
              Đăng ký
            </PretendardText>
            <PretendardText
              onClick={() => {
                navigation(`${route.customer_center}/1`);

                setIsMenuOn(false);
              }}
              style={{
                cursor: "pointer",
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#151515",
                fontWeight: 600,
              }}
            >
              Hỗ trợ khách hàng
            </PretendardText>
          </PCContainer>
        )}
      </HeaderTopWrapper>
      <HeaderWrapper>
        <PCContainer
          style={{
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                navigation(`${route.home}`);

                setIsMenuOn(false);
              }}
              style={{
                width: 150,
                height: 41,
                cursor: "pointer",
              }}
              src="/assets/imgs/logo01.png"
              alt="logo01"
            />

            <div
              style={{
                width: 480,
              }}
            >
              <InputSearch
                placeholder={"Vui lòng tìm kiếm tên sản phẩm."}
                value={keyword}
                onChange={(value) => {
                  setKeyword(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigation(`${route.search}/1?keyword=${keyword}`);

                    setKeyword("");
                  }
                }}
                searchStyle={{
                  top: 9,
                }}
                setDataToKeyword={() => {
                  navigation(`${route.search}/1?keyword=${keyword}`);
                  setKeyword("");
                }}
                autocomplete={"one-time-code"}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginRight: 24,
                  width: 28,
                  height: 28,
                }}
              >
                <img
                  onClick={() => {
                    if (isLogin) {
                      navigation(route.shop_basket);
                    } else {
                      navigation(route.login);
                    }

                    setIsMenuOn(false);
                  }}
                  style={{
                    width: 28,
                    height: 28,
                    cursor: "pointer",
                  }}
                  src="/assets/icons/basket.png"
                  alt="basket"
                />
                {cartTotal > 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: -2,
                      width: 14,
                      height: 14,
                      borderRadius: 999,
                      background: "#FF3D83",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 11,
                        lineHeight: "13.13px",
                        color: "#fff",
                        fontWeight: 700,
                      }}
                    >
                      {cartTotal}
                    </PretendardText>
                  </div>
                ) : null}
              </div>
              <img
                onClick={() => {
                  if (isLogin) {
                    navigation(route.wish_list);
                  } else {
                    navigation(route.login);
                  }

                  setIsMenuOn(false);
                }}
                style={{
                  width: 28,
                  height: 28,
                  marginRight: 24,
                  cursor: "pointer",
                }}
                src="/assets/icons/wish.png"
                alt="wish"
              />

              <img
                onClick={() => {
                  if (isLogin) {
                    navigation(`${route.mypage_order_list}/1`);
                  } else {
                    navigation(route.login);
                  }

                  setIsMenuOn(false);
                }}
                style={{
                  width: 28,
                  height: 28,
                  cursor: "pointer",
                }}
                src="/assets/icons/mypage.png"
                alt="mypage"
              />

              {role === "INFLUENCER" ? (
                <img
                  onClick={() => {
                    navigation(`${route.dashboard}/1`);
                    setIsMenuOn(false);
                  }}
                  style={{
                    width: 28,
                    height: 28,
                    marginLeft: 24,
                    cursor: "pointer",
                  }}
                  src="/assets/icons/ar.png"
                  alt="ar"
                />
              ) : null}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              marginTop: 31,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
              }}
            >
              <img
                onClick={() => {
                  setIsMenuOn(!isMenuOn);
                }}
                style={{
                  width: 32,
                  height: 32,
                  cursor: "pointer",
                }}
                src={
                  isMenuOn
                    ? "/assets/icons/menu_a.png"
                    : "/assets/icons/menu_d.png"
                }
                alt="menu"
              />
            </div>

            {menuList.map((item, index) => {
              return (
                <MenuText
                  onClick={() => {
                    navigation(item.src);

                    setIsMenuOn(false);
                  }}
                  key={index}
                  style={{
                    marginRight: menuList.length == index + 1 ? 0 : 40,
                    color: pathname?.includes(item.src)
                      ? "#ff649c"
                      : "rgb(49, 49, 49)",
                  }}
                >
                  {item.title}
                </MenuText>
              );
            })}
          </div>
        </PCContainer>
      </HeaderWrapper>
      {isMenuOn ? (
        <HeaderBottomWrapper ref={containerRef}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: 1200,
            }}
          >
            {headerMenuStateAtom.map((item, index) => {
              return item.activation ? (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 40,
                    marginRight: 40,
                    minWidth: 160,
                    maxWidth: 160,
                  }}
                >
                  <SubMenuTitleText
                    onClick={() => {
                      if (item.src) {
                        navigation(item.src);
                      } else {
                        navigation(
                          `${route.category_product}/1?categoryValue=${item.id}`
                        );
                      }

                      setIsMenuOn(false);
                    }}
                  >
                    {item.title}
                  </SubMenuTitleText>
                  {item.childCategories.map((item02, index) => {
                    return item02.activation ? (
                      <div key={index}>
                        <SubMenuContentText
                          onClick={() => {
                            if (item02.src) {
                              navigation(item02.src);
                            } else {
                              navigation(
                                `${route.category_product}/1?categoryValue=${item.id}&subCategoryValue=${item02.id}`
                              );
                            }

                            setIsMenuOn(false);
                          }}
                          style={{
                            marginBottom:
                              item.childCategories.length === index + 1
                                ? 0
                                : 15,
                          }}
                        >
                          {item02.title}
                        </SubMenuContentText>
                      </div>
                    ) : null;
                  })}
                </div>
              ) : null;
            })}
          </div>
        </HeaderBottomWrapper>
      ) : null}
    </HeaderSection>
  );
};

export default Header;

const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 10;

  border-bottom: 1px solid #d9d9d9;
`;

const HeaderTopWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fae9ef;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 154px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const MenuText = styled(PretendardText)`
  cursor: pointer;
  font-size: 18px;
  line-height: 26.1px;
  color: rgb(49, 49, 49);
  font-weight: 500;

  &:hover {
    color: #ff649c;
  }
`;

const HeaderBottomWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  background-color: #fffafc;
  position: absolute;
  top: 215px;
  padding: 0px 60px 40px 60px;
  z-index: 20;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 2550, 255, 0.1); /*스크롤바 뒷 배경 색상*/
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SubMenuTitleText = styled(PretendardText)`
  cursor: pointer;
  font-size: 18px;
  line-height: 26.1px;
  color: rgb(49, 49, 49);
  font-weight: 600;
  margin-bottom: 16px;
  &:hover {
    color: #ff649c;
  }
`;

const SubMenuContentText = styled(PretendardText)`
  cursor: pointer;
  font-size: 16px;
  line-height: 23.2px;
  color: rgba(98, 98, 98, 1);
  font-weight: 400;

  &:hover {
    color: #ff649c;
  }
`;
