import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import MainBtn from "../../components/atoms/button/mainBtn";
import Input from "../../components/atoms/input/input";
import FileForm from "../../components/atoms/fileForm/fileForm";
import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";
import TitleInput from "../../components/molecules/input/titleInput";
import TermModal from "../../components/templates/modals/termModal";

import { errorHandler } from "../../util/errorHandler";
import useDisplay from "../../hooks/useDisplay";

import AuthApi from "../../api/authApi";
import CommonApi from "../../api/commonApi";
import { route } from "../../router/route";

let termsType;

const Register = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const navigation = useNavigate();
  const location = useLocation();
  const { joinType, socialId, userData } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [emailErrorM, setEmailErrorM] = useState("");
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailCheckM, setEmailCheckM] = useState("");

  const [pw, setPw] = useState("");
  const [pwType, setPwType] = useState("password");
  const [pwErrorM, setPwErrorM] = useState("");

  const [pwConfirm, setPwConfirm] = useState("");
  const [pwConfirmType, setPwConfirmType] = useState("password");
  const [pwConfirmErrorM, setPwConfirmErrorM] = useState("");

  const [recommender, setRecommender] = useState("");
  const [recommenderErrorM, setRecommenderErrorM] = useState("");
  const [recommenderCheckM, setRecommenderCheckM] = useState("");

  const [type, setType] = useState("CONSUMER");

  const [nickname, setNickname] = useState("");
  const [nicknameErrorM, setNicknameErrorM] = useState("");
  const [nicknameCheck, setNicknameCheck] = useState(true);
  const [nicknameCheckM, setNicknameCheckM] = useState("");

  const [snsLink, setSnsLink] = useState("");
  const [snsLinkErrorM, setSnsLinkErrorM] = useState("");

  const [snsLinkList, setSnsLinkList] = useState([]);

  const [allAgree, setAllAgree] = useState("");
  const [agree01, setAgree01] = useState(false);
  const [agree02, setAgree02] = useState(false);
  const [agree03, setAgree03] = useState(false);
  const [agree04, setAgree04] = useState(false);
  const [agree05, setAgree05] = useState(false);
  const [agree06, setAgree06] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [btnDisabled, setBtnDisabled] = useState(true);

  const [isTermModal, setIsTermModal] = useState(false);

  useEffect(() => {
    setType(searchParams.get("type") ? searchParams.get("type") : "CONSUMER");
    setRecommender(
      searchParams.get("recommendID") ? searchParams.get("recommendID") : ""
    );
    setEmail(userData ? userData.email : "");
  }, [location]);

  useEffect(() => {
    if (agree01 && agree02 && agree03 && agree04 && agree05 && agree06) {
      setAllAgree(true);
    } else {
      setAllAgree(false);
    }
  }, [agree01, agree02, agree03, agree04, agree05, agree06]);

  useEffect(() => {
    if (allAgree) {
      setAgree01(true);
      setAgree02(true);
      setAgree03(true);
      setAgree04(true);
      setAgree05(true);
      setAgree06(true);
    }
  }, [allAgree]);

  useEffect(() => {
    if (type === "CONSUMER") {
      if (
        agree01 &&
        agree02 &&
        agree03 &&
        agree04 &&
        email &&
        emailErrorM === "" &&
        emailCheck &&
        (pw || socialId != null) &&
        (pwErrorM === "" || socialId != null) &&
        (pwConfirm || socialId != null) &&
        (pwConfirmErrorM === "" || socialId != null) &&
        recommenderErrorM === ""
      ) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    } else {
      if (
        agree01 &&
        agree02 &&
        agree03 &&
        agree04 &&
        email &&
        emailErrorM === "" &&
        emailCheck &&
        nickname &&
        nicknameErrorM === "" &&
        nicknameCheck &&
        (pw || socialId != null) &&
        (pwErrorM === "" || socialId != null) &&
        (pwConfirm || socialId != null) &&
        (pwConfirmErrorM === "" || socialId != null) &&
        selectedFiles.length > 0 &&
        snsLink
      ) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [
    agree01,
    agree02,
    agree03,
    agree04,
    email,
    emailCheck,
    emailErrorM,
    nickname,
    nicknameCheck,
    nicknameErrorM,
    snsLink,
    selectedFiles,
    pw,
    pwErrorM,
    pwConfirm,
    pwConfirmErrorM,
  ]);

  const handleChange = async (e) => {
    if (selectedFiles.length >= 3) {
      toast("Tối đa 3 trang");

      return;
    }

    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 3) {
      toast("Tối đa 3 trang");
      return;
    }
    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop()
      );

      fileReader.readAsDataURL(file);
      fileReader.onload = (data) => {
        const fileObject = {
          screenshotOriginFileName: file.name,
          screenshotServerFileName: serverFileName,
          fileUrl: data.target.result,
        };

        setSelectedFiles((prevFiles) => [...prevFiles, fileObject]);
      };
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType) => {
    try {
      const obj = {
        path: "influencer/screenshot/",
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const deleteFile = (fileName) => {
    let temp = selectedFiles.filter(
      (item) => item.screenshotOriginFileName != fileName
    );
    setSelectedFiles(temp);
  };

  useEffect(() => {
    if (email.length > 0) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(email)) {
        return setEmailErrorM("Hình thức email không chính xác.");
      }

      setEmailErrorM("");
      setEmailCheckM("");
      setEmailCheck(false);
    }
  }, [email]);

  const emailCheckFunc = async () => {
    try {
      const obj = {
        email: email,
      };
      const response = await AuthApi.emailCheck(obj);

      setEmailCheck(true);
      setEmailCheckM("Đây là Email có thể sử dụng được.");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }

      setEmailCheck(false);
      setEmailErrorM("Đây là Email đã được đăng ký.");
      setEmailCheckM("");
    }
  };

  useEffect(() => {
    if (nickname.length > 0) {
      setNicknameErrorM("");
      setNicknameCheckM("");
      setNicknameCheck(false);
    }
  }, [nickname]);

  const nicknameCheckFunc = async () => {
    try {
      const obj = {
        nickname: nickname,
      };
      const response = await AuthApi.nicknameCheck(obj);

      setNicknameCheck(true);
      setNicknameCheckM("Đây là dặt tên có thể sử dụng được.");
    } catch (error) {
      setNicknameCheck(false);
      setNicknameErrorM("Đây là đặt tên đã được đăng ký rồi");
      setNicknameCheckM("");
    }
  };

  useEffect(() => {
    if (pw.length > 0) {
      var regType1 = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

      if (!regType1.test(pw)) {
        return setPwErrorM(
          "Vui lòng nhập tổ hợp ký tự đặc biệt, số, tiếng Anh 8-16 ký tự."
        );
      }

      if (pw.length < 8) {
        return setPwErrorM("Vui lòng nhập mật khẩu trên 8 chữ số.");
      }

      setPwErrorM("");
    }
  }, [pw]);

  useEffect(() => {
    if (pwConfirm.length > 0) {
      if (pwConfirm !== pw) {
        return setPwConfirmErrorM("Mật khẩu không khớp.");
      }

      setPwConfirmErrorM("");
    }
  }, [pwConfirm]);

  useEffect(() => {
    if (recommender.length > 0) {
      recommenderCheckFunc();
    }
  }, [recommender]);

  const recommenderCheckFunc = async () => {
    try {
      const obj = {
        recommenderID: recommender,
      };

      const response = await AuthApi.recommendCheck(obj);

      setRecommenderErrorM("");
      setRecommenderCheckM("Đã hoàn thành việc đăng ký ID người giới thiệu.");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (
            error.response.data.message ===
            "입력한 추천인아이디가 존재하지 않습니다."
          ) {
            setRecommenderErrorM(`Đây là người giới thiệu chưa được đăng ký.`);
          }
        }
      }
    }
  };

  const registerFunc = async () => {
    try {
      let obj;
      if (type === "INFLUENCER") {
        let screenshots = selectedFiles.map((item) => {
          return {
            screenshotOriginFileName: item.screenshotOriginFileName,
            screenshotServerFileName: item.screenshotServerFileName,
          };
        });

        let snsLinkTemp = "";
        if (snsLinkList.length > 0) {
          let snsLinkTemp02 = snsLinkList.map((item) => {
            return item.title;
          });

          snsLinkTemp = [snsLink, ...snsLinkTemp02];
        } else {
          snsLinkTemp = [snsLink];
        }

        obj = {
          email: email,
          password: pw,
          recommenderID: recommender,
          nickname: nickname,
          snsLink: JSON.stringify(snsLinkTemp),
          screenshots: screenshots,
          marketingAgreement: agree05,
          adAgreement: agree06,
          role: type,
        };
      } else {
        obj = {
          email: email,
          password: pw,
          recommenderID: recommender,
          marketingAgreement: agree05,
          adAgreement: agree06,
          role: type,
        };
      }

      const response = await AuthApi.join(obj);

      if (type === "INFLUENCER") {
        toast("Đã hoàn tất đăng ký thành viên.");
      } else {
        toast("Đăng ký thành viên thành công.");
      }

      navigation(route.login, { replace: true });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (
            error.response.data.message ===
            "입력한 추천인아이디가 존재하지 않습니다."
          ) {
            return toast(`ID giới thiệu không tồn tại.`);
          }
          return;
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  const registerSocialFunc = async () => {
    try {
      let obj;
      if (type === "INFLUENCER") {
        let screenshots = selectedFiles.map((item) => {
          return {
            screenshotOriginFileName: item.screenshotOriginFileName,
            screenshotServerFileName: item.screenshotServerFileName,
          };
        });

        let snsLinkTemp = "";
        if (snsLinkList.length > 0) {
          let snsLinkTemp02 = snsLinkList.map((item) => {
            return item.title;
          });

          snsLinkTemp = [snsLink, ...snsLinkTemp02];
        } else {
          snsLinkTemp = [snsLink];
        }

        obj = {
          email: email,
          recommenderID: recommender,
          nickname: nickname,
          snsLink: JSON.stringify(snsLinkTemp),
          screenshots: screenshots,
          marketingAgreement: agree05,
          adAgreement: agree06,
          role: type,
          socialId: socialId,
          joinType: joinType,
        };
      } else {
        obj = {
          email: email,
          recommenderID: recommender,
          marketingAgreement: agree05,
          adAgreement: agree06,
          role: type,
          socialId: socialId,
          joinType: joinType,
        };
      }

      const response = await AuthApi.socialJoin(obj);

      if (type === "INFLUENCER") {
        toast("Đã hoàn tất đăng ký thành viên.");
      } else {
        toast("Đăng ký thành viên thành công.");
      }

      navigation(route.login, { replace: true });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (
            error.response.data.message ===
            "입력한 추천인아이디가 존재하지 않습니다."
          ) {
            return toast(`ID giới thiệu không tồn tại.`);
          }
          return;
        }
      } else {
        toast("Đã phát sinh lỗi không rõ.");
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <RegisterSection>
        <PCContainer
          style={{
            flexDirection: "column",
            alignItems: "center",
            padding: isMobile ? "0px 20px" : 0,
          }}
        >
          <PretendardText
            style={{
              fontSize: 24,
              lineHeight: "33.6px",
              color: "#313131",
              textAlign: "center",
              fontWeight: isMobile ? 600 : 500,
              marginBottom: isMobile ? 40 : 50,
            }}
          >
            {isMobile
              ? "Chào mừng bạn!\nTận hưởng nhiều hoạt động\nmua sắm đa dạng cùng\nKoviet."
              : "Chào mừng bạn!\nTận hưởng nhiều hoạt động mua sắm đa dạng cùng Koviet."}
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : 440,
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 18 : 20,
                lineHeight: isMobile ? "26.1px" : "28px",
                color: "#313131",
                textAlign: isMobile ? "left" : "center",
                fontWeight: 600,
              }}
            >
              {isMobile ? "Vui lòng nhập thông tin" : "Vui lòng nhập thông tin"}
            </PretendardText>

            <input
              style={{
                display: "none",
              }}
              type="text"
              name="fakeusernameremembered"
            />
            <input
              style={{
                display: "none",
              }}
              type="password"
              name="fakepasswordremembered"
            />

            <TitleInput
              title={"Email"}
              essential={true}
              placeholder={
                isMobile ? "thư điện tử" : "Vui lòng nhập email của bạn."
              }
              type={"text"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              warningMessage={emailErrorM}
              checkMessage={emailCheckM}
              style={{
                marginTop: isMobile ? 30 : 40,
              }}
              isRightButton={true}
              isRightButtonText={"Xác nhận"}
              rightButtonOnClick={() => {
                if (email && emailErrorM === "") {
                  emailCheckFunc();
                }
              }}
              autocomplete={"one-time-code"}
            />

            {socialId ? null : (
              <>
                <TitleInput
                  inputType={"password"}
                  setInputType={setPwType}
                  type={pwType}
                  title={"Mật khẩu"}
                  essential={true}
                  placeholder={"Vui lòng nhập mật khẩu của bạn."}
                  style={{
                    marginTop: isMobile ? 24 : 20,
                  }}
                  onChange={(e) => {
                    setPw(e.target.value);
                  }}
                  value={pw}
                  warningMessage={pwErrorM}
                  autocomplete={"one-time-code"}
                />

                <TitleInput
                  inputType={"password"}
                  setInputType={setPwConfirmType}
                  type={pwConfirmType}
                  title={"Xác nhận lại mật khẩu"}
                  essential={true}
                  placeholder={"Vui lòng nhập lại mật khẩu của bạn."}
                  style={{
                    marginTop: isMobile ? 24 : 20,
                  }}
                  onChange={(e) => {
                    setPwConfirm(e.target.value);
                  }}
                  value={pwConfirm}
                  warningMessage={pwConfirmErrorM}
                  autocomplete={"one-time-code"}
                />
              </>
            )}

            {type === "CONSUMER" ? (
              <>
                <TitleInput
                  title={"ID người giới thiệu"}
                  essential={false}
                  placeholder={
                    "Vui lòng nhập biệt hiệu của người có ảnh hưởng."
                  }
                  type={"text"}
                  onChange={(e) => {
                    setRecommender(e.target.value);
                  }}
                  value={recommender}
                  warningMessage={recommenderErrorM}
                  checkMessage={recommenderCheckM}
                  style={{
                    marginTop: isMobile ? 24 : 20,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "16.8px",
                    color: "#C4C4C4",
                    fontWeight: 600,
                    marginTop: 4,
                  }}
                >
                  Đối với người giới thiệu là Influencer nhập Nickname, là thành
                  viên thường nhập email.
                </PretendardText>
              </>
            ) : null}

            {type === "INFLUENCER" ? (
              <TitleInput
                title={"Đặt tên"}
                essential={true}
                placeholder={"Vui lòng nhập biệt hiệu của bạn."}
                type={"text"}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
                value={nickname}
                warningMessage={nicknameErrorM}
                checkMessage={nicknameCheckM}
                style={{
                  marginTop: isMobile ? 24 : 20,
                }}
                isRightButton={true}
                isRightButtonText={"Xác nhận"}
                rightButtonOnClick={() => {
                  if (nickname && nicknameErrorM === "") {
                    nicknameCheckFunc();
                  }
                }}
              />
            ) : null}
            {type === "INFLUENCER" ? (
              <div>
                <TitleInput
                  title={"Liên kết SNS"}
                  essential={true}
                  placeholder={"Vui lòng nhập liên kết SNS của bạn."}
                  type={"text"}
                  onChange={(e) => {
                    setSnsLink(e.target.value);
                  }}
                  value={snsLink}
                  warningMessage={snsLinkErrorM}
                  style={{
                    marginTop: isMobile ? 24 : 20,
                  }}
                />

                {snsLinkList.length > 0
                  ? snsLinkList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            position: "relative",
                            marginTop: isMobile ? 24 : 20,
                            flex: 1,
                          }}
                        >
                          <Input
                            onChange={(e) => {
                              item.title = e.target.value;
                            }}
                            type={"text"}
                            placeholder={"Vui lòng nhập liên kết SNS của bạn."}
                          />
                          {snsLinkList.length === index + 1 ? (
                            <img
                              onClick={() => {
                                let temp = snsLinkList.filter(
                                  (item02) => item02.id !== index
                                );

                                setSnsLinkList(temp);
                              }}
                              style={{
                                position: "absolute",
                                right: 13,
                                width: 18,
                                height: 18,
                                cursor: "pointer",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                              src="/assets/icons/ic-closed-30.png"
                              alt="ic-closed-30"
                            />
                          ) : null}
                        </div>
                      );
                    })
                  : null}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <div
                    onClick={() => {
                      let temp = [
                        ...snsLinkList,
                        {
                          id: snsLinkList.length,
                          snsLink: "",
                        },
                      ];

                      setSnsLinkList(temp);
                    }}
                    style={{
                      cursor: "pointer",
                      padding: "6px 12px",
                      borderRadius: 6,
                      border: "2px solid #E8E8E8",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 14,
                        lineHeight: "19.6px",
                        color: "#313131",
                        fontWeight: 600,
                        marginRight: 2,
                      }}
                    >
                      Thêm liên kết
                    </PretendardText>
                    <img
                      style={{
                        width: 14,
                        height: 14,
                      }}
                      src="/assets/icons/ic_plus_14.png"
                      alt="ic_plus_14"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {type === "INFLUENCER" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginBottom: 4,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 700,
                    }}
                  >
                    Ảnh chụp màn hình
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#FF3D83",
                      fontWeight: 700,
                    }}
                  >
                    *
                  </PretendardText>
                </div>
                <PretendardText
                  style={{
                    fontSize: 12,
                    lineHeight: "16.8px",
                    color: "#939393",
                    fontWeight: 500,
                    marginBottom: 8,
                  }}
                >
                  *Vui lòng chụp và tải hình SNS của bạn <br />
                  để xác minh danh tính. (Tối đa 3 ảnh)
                </PretendardText>
                <FileForm multiple={"multiple"} handleChange={handleChange} />
                {selectedFiles.length > 0
                  ? selectedFiles.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: 10,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "23.2px",
                              color: "#313131",
                              fontWeight: 700,
                            }}
                          >
                            {item.screenshotOriginFileName}
                          </PretendardText>

                          <img
                            onClick={() => {
                              deleteFile(item.screenshotOriginFileName);
                            }}
                            style={{
                              width: 18,
                              height: 18,
                              cursor: "pointer",
                            }}
                            src="/assets/icons/ic-closed-30.png"
                            alt="ic-closed-30"
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}

            <RightLabelCheckbox
              inputStyle={{
                marginTop: isMobile ? 40 : 36,
                marginBottom: 15,
              }}
              checked={allAgree}
              onChange={() => {
                if (allAgree) {
                  setAgree01(false);
                  setAgree02(false);
                  setAgree03(false);
                  setAgree04(false);
                  setAgree05(false);
                  setAgree06(false);
                }

                setAllAgree(!allAgree);
              }}
              text={"Đồng ý với tất cả các điều khoản và điều kiện"}
            />

            <div
              style={{
                height: 1,
                background: "#DCDCDC",
                width: "100%",
                marginBottom: 15,
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: isMobile ? 20 : 12,
              }}
            >
              <RightLabelCheckbox
                checked={agree01}
                onChange={() => {
                  setAgree01(!agree01);
                }}
                text={"[Bắt buộc] Đồng ý thu thập và sử dụng thông tin cá nhân"}
              />
              <PretendardText
                onClick={() => {
                  termsType = 4;
                  setIsTermModal(true);
                }}
                style={{
                  textAlign: "right",
                  fontSize: 16,
                  color: "#939393",
                  fontWeight: 600,
                  cursor: "pointer",
                  minWidth: 101,
                  textDecoration: "underline",
                }}
              >
                Xem tất cả
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: isMobile ? 20 : 12,
              }}
            >
              <RightLabelCheckbox
                checked={agree02}
                onChange={() => {
                  setAgree02(!agree02);
                }}
                text={"[Bắt buộc] Đồng ý với Điều khoản sử dụng của Koviet"}
              />
              <PretendardText
                onClick={() => {
                  termsType = 2;
                  setIsTermModal(true);
                }}
                style={{
                  textAlign: "right",
                  fontSize: 16,
                  color: "#939393",
                  fontWeight: 600,
                  cursor: "pointer",
                  minWidth: 101,
                  textDecoration: "underline",
                }}
              >
                Xem tất cả
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: isMobile ? 20 : 12,
              }}
            >
              <RightLabelCheckbox
                checked={agree03}
                onChange={() => {
                  setAgree03(!agree03);
                }}
                text={
                  "[Bắt buộc] Đồng ý với các điều khoản và điều kiện giao dịch tài chính điện tử"
                }
              />
              <PretendardText
                onClick={() => {
                  termsType = 3;
                  setIsTermModal(true);
                }}
                style={{
                  textAlign: "right",
                  fontSize: 16,
                  color: "#939393",
                  fontWeight: 600,
                  cursor: "pointer",
                  minWidth: 101,
                  textDecoration: "underline",
                }}
              >
                Xem tất cả
              </PretendardText>
            </div>
            <RightLabelCheckbox
              textStyle={{
                maxWidth: 315,
              }}
              inputStyle={{
                marginBottom: isMobile ? 20 : 12,
              }}
              checked={agree04}
              onChange={() => {
                setAgree04(!agree04);
              }}
              text={"[Bắt buộc] Phải từ 14 tuổi trở lên"}
            />
            <RightLabelCheckbox
              textStyle={{
                maxWidth: 315,
              }}
              inputStyle={{
                marginBottom: isMobile ? 20 : 12,
              }}
              checked={agree05}
              onChange={() => {
                setAgree05(!agree05);
              }}
              text={
                "[Tùy chọn] Đồng ý thu thập và sử dụng thông tin cá nhân cho mục đích tiếp thị"
              }
            />
            <RightLabelCheckbox
              textStyle={{
                maxWidth: 315,
              }}
              inputStyle={{}}
              checked={agree06}
              onChange={() => {
                setAgree06(!agree06);
              }}
              text={"[Tùy chọn] Nhận thông tin quảng cáo"}
            />

            <MainBtn
              disabled={btnDisabled}
              onClick={() => {
                if (socialId) {
                  registerSocialFunc();
                } else {
                  registerFunc();
                }
              }}
              style={{
                marginTop: 40,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                Đăng ký thành viên đã hoàn tất
              </PretendardText>
            </MainBtn>
          </div>
        </PCContainer>
      </RegisterSection>

      {isTermModal ? (
        <TermModal
          setVisiable={setIsTermModal}
          isScroll={false}
          termCategory={termsType}
        />
      ) : null}
    </>
  );
};

export default Register;

const RegisterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
`;
