import styled from "styled-components";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import MainBorderBtn from "../../atoms/button/mainBorderBtn";
import { useState } from "react";

function Description({ style, content }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [isDetail, setIsDetail] = useState(false);

  return (
    <>
      <DescriptionContain style={style}>
        <div
          className="ql-editor"
          style={{
            width: "100%",
            height: isDetail ? "100%" : 600,
            overflow: "hidden",
            maxWidth: isTablet ? 640 : "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />

        {isDetail ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 2,
              width: "100%",
              height: 600,
              background:
                "linear-gradient(180deg,rgba(217, 217, 217, 0),rgba(255, 255, 255, 0.8))",
            }}
          />
        )}

        {isDetail ? null : (
          <MainBorderBtn
            onClick={() => {
              setIsDetail(true);
            }}
            style={{
              zIndex: 3,
              bottom: 50,
              width: 213,
              height: 47,
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 600,
              }}
            >
              Xem thêm
            </PretendardText>
          </MainBorderBtn>
        )}
      </DescriptionContain>
    </>
  );
}

export default Description;

const DescriptionContain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
`;
